import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import OwnerVerification from '../OwnerVerification/OwnerVerification';
import ConditionReport from '../ConditionReport/ConditionReport';
import HistoryReport from '../HistoryReport/HistoryReport';
import EstimatedValue from '../EstimatedValue/EstimatedValue';
import AuctionMyCar from '../AuctionMyCar/AuctionMyCar';
import FinalizeSale from '../FinalizeSale/FinalizeSale';
import LeftSideContents from '../LeftSideContents/LeftSideContents';
import RightSideContents from '../RightSideContents/RightSideContents';

const VehicleDetails = ({ car }) => {
    const [selectedTab, setSelectedTab] = useState('owner-verification');

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    return (
        <div>
            <div className="d-flex">
                <div className="w-25">
                    <Nav className="flex-column" variant='pills'>
                        <Nav.Link
                            active={selectedTab === 'owner-verification'}
                            eventKey="owner-verification"
                            onClick={() => handleTabSelect('owner-verification')}
                        >
                            Owner Verification
                        </Nav.Link>
                        <Nav.Link
                            eventKey="condition-report"
                            onClick={() => handleTabSelect('condition-report')}
                        >
                            Condition Report
                        </Nav.Link>
                        <Nav.Link
                            eventKey="history-report"
                            onClick={() => handleTabSelect('history-report')}
                        >
                            History Report
                        </Nav.Link>
                        <Nav.Link
                            eventKey="estimated-value"
                            onClick={() => handleTabSelect('estimated-value')}
                        >
                            Estimated Value
                        </Nav.Link>
                        <Nav.Link
                            eventKey="auction-my-car"
                            onClick={() => handleTabSelect('auction-my-car')}
                        >
                            Auction My Car
                        </Nav.Link>
                        <Nav.Link
                            eventKey="finalize-sale"
                            onClick={() => handleTabSelect('finalize-sale')}
                        >
                            Finalize Sale
                        </Nav.Link>
                    </Nav>
                </div>
                <div className="w-50">
                    <LeftSideContents selectedTab={selectedTab} car={car} />
                </div>
                <div className='w-25'>
                    <RightSideContents selectedTab={selectedTab} car={car} />
                </div>
            </div>
        </div>
    );
};

export default VehicleDetails;