import React from 'react';

const EstimatedValue = () => {
    return (
        <div>
            Owner Verification
        </div>
    )
};

export default EstimatedValue;