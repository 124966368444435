import React from 'react';
import { Navbar, Nav, FormControl, Form, Button } from 'react-bootstrap';

const Header = () => {
    return (
        <div className="header-container">
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home" className="header-logo">
                    Auction App
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Form className="header-search">
                        <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                        <Button variant="outline-success">Search</Button>
                    </Form>
                    <Nav className="ml-auto">
                        <Nav.Link href="#username" className="header-username">
                            John Doe
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default Header;
