import React from 'react';
import OwnerInformation from '../OwnerInformation/OwnerInformation';

const ConditionReport = ({ car }) => {
    return (
        <div>
            condition report
            <OwnerInformation car={car} />
        </div>
    )
};

export default ConditionReport;