import React from 'react';
import OwnerInformation from '../OwnerInformation/OwnerInformation';
import AuctionMyCar from '../AuctionMyCar/AuctionMyCar';
import FinalizeSale from '../FinalizeSale/FinalizeSale';

const RightSideContents = ({ selectedTab, car }) => {
    console.log(selectedTab)

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'owner-verification':
            case 'condition-report':
            case 'history-report':
            case 'estimated-value':
                return <OwnerInformation car={car} />;
            case 'auction-my-car':
                return <AuctionMyCar car={car} />;
            case 'finalize-sale':
                return <FinalizeSale car={car} />;
            default:
                return null;
        }
    };

    return (
        <div>
            {renderTabContent()}
        </div>
    )
};

export default RightSideContents;