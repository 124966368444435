import React from 'react';

const FinalizeSale = () => {
    return (
        <div>
            Owner Verification
        </div>
    )
};

export default FinalizeSale;