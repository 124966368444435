import React, { useState } from 'react';
import Header from './components/Header/Header';
import VehicleDetails from './components/VehicleDetails/VehicleDetails';
import VehicleList from './components/VehicleList/VehicleList';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
	const [selectedCar, setSelectedCar] = useState(null);

	const handleCarSelect = (car) => {
		setSelectedCar(car);
	};

	return (
		<div className='app'>
			<Header />
			{selectedCar ? (
				<VehicleDetails car={selectedCar} />
			) : (
				<VehicleList onSelectCar={handleCarSelect} selectedCar={selectedCar} />
			)}
		</div>
	);
};

export default App;
