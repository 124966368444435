import React, { useState } from 'react';
import { Button, Card, Form, Tabs, Tab, Col, Row, FloatingLabel, Badge } from 'react-bootstrap';


const OwnerInformation = ({ car }) => {
    const [activeTab, setActiveTab] = useState('owner');
    const [userInfoOwner, setUserInfoOwner] = useState({
        firstName: 'Erica',
        lastName: 'Jefferson',
        phone: '213-839-8427',
        email: 'ejeff99@gmail.com',
        driverLicenseNumber: 'B2340024',
        driverLicenseState: 'CA',
        address1: '1515 East Main St',
        address2: 'Unit 25A',
        city: 'Los Angeles',
        state: 'CA',
        zip: '90010',
    });

    const [userInfoCoOwner, setUserInfoCoOwner] = useState({
        firstName: 'Derrick',
        lastName: 'Jefferson',
        phone: '909-544-8774',
        email: 'derrick.jeffy1@gmail.com',
        driverLicenseNumber: 'B3853048',
        driverLicenseState: 'CA',
        address1: '1515 East Main St',
        address2: 'Unit 25A',
        city: 'Los Angeles',
        state: 'CA',
        zip: '90010',
    });
    const [lienholderInfo, setLienholderInfo] = useState({
        lienholder: 'Chase Auto Finance',
        accountNumber: '6542-4438',
        phone: '800-334-8848',
        payoffAmount: '$9,033.71',
        payoffDueDate: '08/15/2023',
        address1: '1110 Broadway Ave',
        address2: '2nd Floor Suite 1A',
        city: 'Westwood',
        state: 'CA',
        zip: '91132',
    });

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleFormChange = (e, userType) => {
        const { name, value } = e.target;
        if (userType === 'owner') {
            setUserInfoOwner((prevUserInfo) => ({ ...prevUserInfo, [name]: value }));
        } else if (userType === 'coOwner') {
            setUserInfoCoOwner((prevUserInfo) => ({ ...prevUserInfo, [name]: value }));
        } else if (userType === 'lienholder') {
            setLienholderInfo((prevLienholderInfo) => ({ ...prevLienholderInfo, [name]: value }));
        }
    };

    const handleSubmit = (e, userType) => {
        e.preventDefault();
        // Process form submission here, you can access the form data for each user type
        if (userType === 'owner') {
            console.log('Form data for Owner:', userInfoOwner);
        } else if (userType === 'coOwner') {
            console.log('Form data for Co-owner:', userInfoCoOwner);
        } else if (userType === 'lienholder') {
            console.log('Form data for Lienholder:', lienholderInfo);
        }
    };


    return (
        <div className='owner-information'>
            <Card>
                <Card.Body className="d-flex">
                    <img src={car.imgSrc} alt="Car Thumbnail" className="car-thumbnail" />
                    <div className="car-info">
                        <Badge>
                            {car.name}
                        </Badge>
                        <Card.Text>
                            <strong>{car.year} {car.make} {car.model} {car.trim}</strong>
                        </Card.Text>
                        <Card.Text>
                            {car.vin}
                        </Card.Text>
                        <Card.Text>
                            Miles: {car.miles}
                        </Card.Text>
                    </div>
                </Card.Body>
            </Card>
            <Tabs defaultActiveKey="owner" id="user-info-tabs">
                <Tab eventKey="owner" title="Owner">
                    <Form onSubmit={(e) => handleSubmit(e, 'owner')}>
                        <h5>Owner Information</h5>
                        <Form.Group controlId="firstNameOwner">
                            <FloatingLabel
                                controlId='firstNameOwnerLabel'
                                label="First name"
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={userInfoOwner.firstName}
                                    onChange={(e) => handleFormChange(e, 'owner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="lastNameOwner">
                            <FloatingLabel
                                controlId='lastNameOwnerLabel'
                                label="Last name"
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={userInfoOwner.lastName}
                                    onChange={(e) => handleFormChange(e, 'owner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="phoneOwner">
                            <FloatingLabel
                                controlId='phoneOwnerLabel'
                                label="Phone"
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={userInfoOwner.phone}
                                    onChange={(e) => handleFormChange(e, 'owner')}
                                />

                            </FloatingLabel>

                        </Form.Group>
                        <Form.Group controlId="emailOwner">
                            <FloatingLabel
                                controlId='emailOwnerLabel'
                                label='Email'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={userInfoOwner.email}
                                    onChange={(e) => handleFormChange(e, 'owner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="driverLicenseNumberOwner">
                                    <FloatingLabel
                                        controlId='driverLicenseNumberOwnerLabel'
                                        label='Driver License'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="driverLicenseNumber"
                                            value={userInfoOwner.driverLicenseNumber}
                                            onChange={(e) => handleFormChange(e, 'owner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="driverLicenseStateOwner">
                                    <FloatingLabel
                                        controlId='driveLicenseStateOwnerLabel'
                                        label='State'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="driverLicenseState"
                                            value={userInfoOwner.driverLicenseState}
                                            onChange={(e) => handleFormChange(e, 'owner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="address1Owner">
                            <FloatingLabel
                                controlId='address1OwnerLabel'
                                label='Address 1'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="address1"
                                    value={userInfoOwner.address1}
                                    onChange={(e) => handleFormChange(e, 'owner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="address2Owner">
                            <FloatingLabel
                                controlId='address2OwnerLabel'
                                label='Address 2'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="address2"
                                    value={userInfoOwner.address2}
                                    onChange={(e) => handleFormChange(e, 'owner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="cityOwner">
                            <FloatingLabel
                                controlId='cityOwnerLabel'
                                label='City'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={userInfoOwner.city}
                                    onChange={(e) => handleFormChange(e, 'owner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="stateOwner">
                                    <FloatingLabel
                                        content='stateOwnerLabel'
                                        label='State'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="state"
                                            value={userInfoOwner.state}
                                            onChange={(e) => handleFormChange(e, 'owner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="zipOwner">
                                    <FloatingLabel
                                        controlId='zipOwnerLabel'
                                        label='ZIP'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="zip"
                                            value={userInfoOwner.zip}
                                            onChange={(e) => handleFormChange(e, 'owner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>

                        <h5>Co-Owner Information</h5>
                        <Form.Group controlId="firstNameCoOwner">
                            <FloatingLabel
                                controlId='firstNameCoOwnerLabel'
                                label="First name"
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={userInfoCoOwner.firstName}
                                    onChange={(e) => handleFormChange(e, 'coOwner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="lastNameCoOwner">
                            <FloatingLabel
                                controlId='lastNameCoOwnerLabel'
                                label="Last name"
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={userInfoCoOwner.lastName}
                                    onChange={(e) => handleFormChange(e, 'coOwner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="phoneCoOwner">
                            <FloatingLabel
                                controlId='phoneCoOwnerLabel'
                                label="Phone"
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={userInfoCoOwner.phone}
                                    onChange={(e) => handleFormChange(e, 'coOwner')}
                                />

                            </FloatingLabel>

                        </Form.Group>
                        <Form.Group controlId="emailCoOwner">
                            <FloatingLabel
                                controlId='emailCoOwnerLabel'
                                label='Email'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={userInfoCoOwner.email}
                                    onChange={(e) => handleFormChange(e, 'coOwner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="driverLicenseNumberCoOwner">
                                    <FloatingLabel
                                        controlId='driverLicenseNumberCoOwnerLabel'
                                        label='Driver License'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="driverLicenseNumber"
                                            value={userInfoCoOwner.driverLicenseNumber}
                                            onChange={(e) => handleFormChange(e, 'coOwner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="driverLicenseStateCoOwner">
                                    <FloatingLabel
                                        controlId='driveLicenseStateCoOwnerLabel'
                                        label='State'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="driverLicenseState"
                                            value={userInfoCoOwner.driverLicenseState}
                                            onChange={(e) => handleFormChange(e, 'coOwner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="address1CoOwner">
                            <FloatingLabel
                                controlId='address1CoOwnerLabel'
                                label='Address 1'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="address1"
                                    value={userInfoCoOwner.address1}
                                    onChange={(e) => handleFormChange(e, 'coOwner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="address2CoOwner">
                            <FloatingLabel
                                controlId='address2CoOwnerLabel'
                                label='Address 2'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="address2"
                                    value={userInfoCoOwner.address2}
                                    onChange={(e) => handleFormChange(e, 'coOwner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="cityCoOwner">
                            <FloatingLabel
                                controlId='cityCoOwnerLabel'
                                label='City'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={userInfoCoOwner.city}
                                    onChange={(e) => handleFormChange(e, 'coOwner')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="stateCoOwner">
                                    <FloatingLabel
                                        content='stateCoOwnerLabel'
                                        label='State'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="state"
                                            value={userInfoCoOwner.state}
                                            onChange={(e) => handleFormChange(e, 'coOwner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="zipCoOwner">
                                    <FloatingLabel
                                        controlId='zipCoOwnerLabel'
                                        label='ZIP'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="zip"
                                            value={userInfoCoOwner.zip}
                                            onChange={(e) => handleFormChange(e, 'coOwner')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* <Button type="submit">Submit</Button> */}
                    </Form>
                </Tab>

                <Tab eventKey="lienholder" title="Lienholder">
                    <Form onSubmit={handleSubmit}>
                        <h5>Lienholder Information</h5>
                        <Form.Group controlId="lienholder">
                            <FloatingLabel
                                controlId='lienholderLabel'
                                label='Lienholder'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="lienholder"
                                    value={lienholderInfo.lienholder}
                                    onChange={(e) => handleFormChange(e, 'lienholder')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="accountNumber">
                            <FloatingLabel
                                controlId='accountNumberLabel'
                                label='Account Number'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="accountNumber"
                                    value={lienholderInfo.accountNumber}
                                    onChange={(e) => handleFormChange(e, 'lienholder')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="phoneLienholder">
                                    <FloatingLabel
                                        controlId='phoneLienholderLabel'
                                        label='Phone'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="phone"
                                            value={lienholderInfo.phone}
                                            onChange={(e) => handleFormChange(e, 'lienholder')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="payoffAmount">
                                    <FloatingLabel
                                        controlId='payoffAmountLabel'
                                        label='Payoff Amount'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="payoffAmount"
                                            value={lienholderInfo.payoffAmount}
                                            onChange={(e) => handleFormChange(e, 'lienholder')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="payoffDueDate">
                                    <FloatingLabel
                                        controlId='payoffDueDateLabel'
                                        label='Payoff Due Date'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="payoffDueDate"
                                            value={lienholderInfo.payoffDueDate}
                                            onChange={(e) => handleFormChange(e, 'lienholder')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="address1Lienholder">
                            <FloatingLabel
                                controlId='address1LienHolderLabel'
                                label='Address1'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="address1"
                                    value={lienholderInfo.address1}
                                    onChange={(e) => handleFormChange(e, 'lienholder')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="address2Lienholder">
                            <FloatingLabel
                                controlId='address2LienholderLabel'
                                label='Address 2'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="address2"
                                    value={lienholderInfo.address2}
                                    onChange={(e) => handleFormChange(e, 'lienholder')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="cityLienholder">
                            <FloatingLabel
                                controlId='cityLienholderLabel'
                                label='City'
                                className='mb-2'
                            >
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={lienholderInfo.city}
                                    onChange={(e) => handleFormChange(e, 'lienholder')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="stateLienholder">
                                    <FloatingLabel
                                        controlId='stateLienholderLabel'
                                        label='State'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="state"
                                            value={lienholderInfo.state}
                                            onChange={(e) => handleFormChange(e, 'lienholder')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="zipLienholder">
                                    <FloatingLabel
                                        controlId='zipLienholderLabel'
                                        label='ZIP'
                                        className='mb-2'
                                    >
                                        <Form.Control
                                            type="text"
                                            name="zip"
                                            value={lienholderInfo.zip}
                                            onChange={(e) => handleFormChange(e, 'lienholder')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Button type="submit">Submit Lienholder</Button> */}
                    </Form>
                </Tab>
            </Tabs>
        </div>
    )
};

export default OwnerInformation;