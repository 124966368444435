import React from 'react';
import { Card, Badge, ListGroup } from 'react-bootstrap';
import img1Src from '../../assets/2021 Honda CR-V (selected vehicle)/Exterior/exterior_8.jpg'
import img2Src from '../../assets/2016 Mazda CX-5/Ext/right_front_diagonal_8_image.jpg';
import img3Src from '../../assets/2021 Infiniti QX50/Exterior/exterior_8.jpg';
import img4Src from '../../assets/2022 Kia Rio/Exterior/exterior_8.jpg';
import img5Src from '../../assets/2022 Toyota Corolla/Exterior/exterior_8.jpg';
import img6Src from '../../assets/2017 Cadillac Escalade 1.jpg';
import img7Src from '../../assets/2023 Lexus RZ 1.jpg';
import img8Src from '../../assets/2021 Geneis GV80.jpg';

const VehicleList = ({ onSelectCar, selectedCar }) => {
    const vehicles = [
        {
            id: 1,
            year: 2021,
            make: 'Honda',
            model: 'CR-V',
            miles: 85765,
            name: 'E. Jefferson',
            status: 'Inspection Ready',
            imgSrc: img1Src,
            vin: '12345678901234567'
        },
        {
            id: 2,
            year: 2016,
            make: 'Mazda',
            model: 'CX-5',
            miles: 108332,
            name: 'F. Vaughn',
            status: 'Inspection Ready',
            imgSrc: img2Src
        },
        {
            id: 3,
            year: 2021,
            make: 'Infiniti',
            model: 'QX50',
            miles: 24184,
            name: 'G. Smart',
            status: 'Inspection Ready',
            imgSrc: img3Src
        },
        {
            id: 4,
            year: 2022,
            make: 'Kia',
            model: 'Ria',
            miles: 9422,
            name: 'Q. David',
            status: 'Auction Running',
            imgSrc: img4Src
        },
        {
            id: 5,
            year: 2022,
            make: 'Toyota',
            model: 'Corolla',
            miles: 19237,
            name: 'L. Allen',
            status: 'Now Inspecting',
            imgSrc: img5Src
        },
        {
            id: 6,
            year: 2017,
            make: 'Cadillac',
            model: 'Escalade',
            miles: 57238,
            name: 'N Smith',
            status: 'Now Inspecting',
            imgSrc: img6Src
        },
        {
            id: 7,
            year: 2023,
            make: 'Lexus',
            model: 'RZ',
            miles: 13183,
            name: 'F. Chavez',
            status: 'Waiting for Inspection',
            imgSrc: img7Src
        },
        {
            id: 8,
            year: 2021,
            make: 'Genesis',
            model: 'GV80',
            miles: 22848,
            name: 'F. Monroe',
            status: 'Waiting for Inspection',
            imgSrc: img8Src
        }
    ];

    const getButtonVariant = (carStatus) => {
        switch (carStatus) {
            case 'Inspection Ready':
                return 'warning';
            case 'Auction Running':
                return 'success';
            case 'Now Inspecting':
                return 'info';
            case 'Waiting for Inspection':
                return 'primary'
            default:
                break;
        }
    }

    return (
        <div>
            {vehicles.map((car) => (
                <Card
                    key={car.id}
                    onClick={() => onSelectCar(car)}
                    className={`my-3 car-card ${car === selectedCar ? 'selected' : ''}`}
                >
                    <Card.Body className="d-flex">
                        <img src={car.imgSrc} alt="Car Thumbnail" className="car-thumbnail" />
                        <div className="car-info">
                            <Badge>
                                {car.name}
                            </Badge>
                            <Card.Text>
                                <strong>{car.year} {car.make}</strong>
                            </Card.Text>
                            <Card.Text>
                                <strong>{car.model} {car.trim}</strong>
                            </Card.Text>
                            <Card.Text>
                                Miles: {car.miles}
                            </Card.Text>
                        </div>
                        <div className="status-indicator">
                            <Badge bg={getButtonVariant(car.status)}>
                                {car.status.toUpperCase()}
                            </Badge>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        OpenTrade Cerritos, CA
                    </Card.Footer>
                </Card>
            ))}
        </div>
    )
};

export default VehicleList;