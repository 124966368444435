import React from 'react';
import OwnerVerification from '../OwnerVerification/OwnerVerification';
import ConditionReport from '../ConditionReport/ConditionReport';
import HistoryReport from '../HistoryReport/HistoryReport';
import EstimatedValue from '../EstimatedValue/EstimatedValue';
import AuctionMyCar from '../AuctionMyCar/AuctionMyCar';
import FinalizeSale from '../FinalizeSale/FinalizeSale';

const LeftSideContents = ({ selectedTab, car }) => {
    const renderTabContent = () => {
        switch (selectedTab) {
            case 'owner-verification':
                return <OwnerVerification car={car} />
            case 'condition-report':
                return <ConditionReport car={car} />
            case 'history-report':
                return <HistoryReport car={car} />
            case 'estimate-value':
                return <EstimatedValue car={car} />
            case 'auction-my-car':
                return <AuctionMyCar car={car} />
            case 'finalize-sale':
                return <FinalizeSale car={car} />
            default:
                return null;
        }
    };

    return (
        <div>
            {renderTabContent()}
        </div>
    )
};

export default LeftSideContents;