import React from 'react';
import ksrSrc from '../../assets/KSR doc 1.png';

const OwnerVerification = () => {
    return (
        <div>
            <img src={ksrSrc} alt="KSR" />
        </div>
    )
};

export default OwnerVerification;